<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <Avatar size="large" class="primary" icon="pi pi-circle-off" />
                                        </div>
                                        <div class="ml-2">
                                            <div class="text-2xl text-primary font-bold">Minimum Entry Requirements</div>
                                            <div class="text-sm text-500"> Create new record </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-4 text-right" >
                                <Button label="Add New Requirement" icon="pi pi-plus"  @click="openPageDialog({ page: 'minimum_entry_requirements/add', url: `/minimum_entry_requirements/add` }, { persistent: true,closeBtn: true })"  class="w-fullbg-primary "  />
                            </div>
                        </div>
                    </div>
                </section>
                <hr />
            </template>
            <section class="page-section " >
                <div class="container-fluid">
                    <div class="grid ">
                        <div class="col comp-grid" >
                            <div class="flex align-items-center justify-content-around">
                                <div v-if="searchText" class="surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-cente">
                                    Search
                                    <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
                                </div>
                            </div>
                            <div class="">
                                <div >
                                    <template v-if="showBreadcrumbs && $route.query.tag">
                                        <Breadcrumb :home="{icon: 'pi pi-home', to: '/minimum_entry_requirements'}" :model="pageBreadCrumb" />
                                    </template>
                                    <div class="relative-position">
                                        <div class="grid">
                                            <!-- Master Page Start -->
                                            <div class="col-12">
                                                <div class="page-records" v-if="!loading">
                                                    <div class="grid">
                                                        <template v-for="(data, i) of records" :key="i">
                                                            <div class="col-12">
                                                                <div class="card" :class="{ 'item-selected': isCurrentRecord(data) }">
                                                                    <div class="grid" >
                                                                        <div class="col">
                                                                            <div class="text-lg text-primary font-bold cursor-pointer" v-ripple @click="openPageDialog({ page: 'minimum_entry_requirements/view', url: `minimum_entry_requirements/view/${data.minimum_entry_requirement_id}`}, { closeBtn: true })">{{ data.title }}</div>
                                                                            <div class="text-500" lines="2">{{ data.description }}</div>
                                                                        </div>
                                                                        <div class="col-fixed text-right" style="width:auto">
                                                                            <div class="flex justify-content-end">
                                                                                <Menubar class="p-0" ref="actionMenu" :model="getActionMenuModel(data)" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                                <!-- page loading indicator -->
                                                <template v-if="loading">
                                                    <div>
                                                        <table class="p-datatable-table w-full">
                                                            <tbody>
                                                                <tr v-for="n in 5" :key="n">
                                                                    <td class="text-left"  style="width:100%">
                                                                        <Skeleton height="5rem" class="m-2" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div v-show="loading" class="flex align-items-center justify-content-center text-gray-500 ">
                                                        <div><ProgressSpinner style="width:30px;height:30px;" /> </div>
                                                         
                                                    </div>
                                                </template>
                                                <!-- end of page loading indicator-->
                                                <!-- Empty record -->
                                                <template v-if="!loading && !records.length">
                                                    <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                                        No record found
                                                    </div>
                                                </template>
                                                <!-- end of empty record-->
                                                <!-- pagination component-->
                                                <template v-if="showFooter">
                                                    <div class="">
                                                        <div class="flex justify-content-between">
                                                            <div class="flex-grow-0">
                                                                <div class="flex">
                                                                    <div v-if="selectedItems.length" class="m-2">
                                                                        <Button @click="deleteItem(selectedItems)" icon="pi pi-trash" class="p-button-danger" title="Delete Selected" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div  class="flex-grow-1">
                                                                <div v-if="!loading && totalPages > 1" class="flex  justify-content-center">
                                                                    <div  class="m-2">
                                                                        <Button :disabled="pagination.page <= 1" label="Previous" @click="pagination.page--" icon="pi pi-arrow-left" />
                                                                    </div>
                                                                    <div class="m-2">
                                                                        <Button :disabled="pagination.page >= totalPages" label="Next" @click="pagination.page++" icon="pi pi-arrow-right" icon-pos="right" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <!-- end of pagination component-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
		//[--PAGE-IMPORT-STATEMENT--]
	import { defineAsyncComponent, ref } from "vue";
	import { PageMixin } from "../../mixins/page.js";
	import { ListPageMixin } from "../../mixins/listpage.js";
	import { mapActions, mapGetters, mapState } from "vuex";
	export default {
		name: 'listMinimumentryrequirementsPage',
		components: {
			//[--PAGE-COMPONENT-NAME--]
        },
		mixins: [PageMixin, ListPageMixin ],
		props: {
			primaryKey : {
				type : String,
				default : 'minimum_entry_requirement_id',
			},
			pageName : {
				type : String,
				default : 'minimum_entry_requirements',
			},
			routeName : {
				type : String,
				default : 'minimum_entry_requirementslist',
			},
			apiPath : {
				type : String,
				default : 'minimum_entry_requirements/index',
			},
			mergeRecords : {
				type : Boolean,
				default : false,
			},
			multiCheckbox: {
				type: Boolean,
				default: true,
			},
			msgBeforeDelete: {
				type: String,
				default: "Are you sure you want to delete this record?",
			},
		},
		data() {
            return {
			}
		},
		computed: {
			pageTitle:{
				get: function () {
					//set browser page title
					return "Minimum Entry Requirements"
				}
			},
			records: {
				get: function () {
					return this.$store.getters["minimum_entry_requirements/records"];
				},
				set: function (value) {
					this.$store.commit("minimum_entry_requirements/setRecords", value);
				},
			},
			currentRecord: {
				get: function () {
					return this.$store.getters["minimum_entry_requirements/currentRecord"];
				},
				set: function (value) {
					this.$store.commit("minimum_entry_requirements/setCurrentRecord", value);
				},
			},
		},
		watch: {
			apiUrl: function () {
				this.load();
			},
		},
		methods: {
			...mapActions("minimum_entry_requirements", ["fetchRecords", "deleteRecord"]),
				getActionMenuModel(data){
					return [
  {
    label: "Details",
    command: (event) => { this.openPageDialog({ page:'minimum_entry_requirements/view', url: `/minimum_entry_requirements/view/${data.minimum_entry_requirement_id}` }, { closeBtn: true }) },
    icon: "pi pi-eye"
  },
  {
    label: "Edit",
    command: (event) => { this.openPageDialog({ page:'minimum_entry_requirements/edit', url: `/minimum_entry_requirements/edit/${data.minimum_entry_requirement_id}` }, { persistent: true,closeBtn: true }) },
    icon: "pi pi-pencil"
  }
]
				},
			load: function() {
				if (!this.loading) {
					this.loading = true;
					this.currentRecord = null;
					let url = this.apiUrl;
					let payload = {
						url,
						merge: this.mergeRecords
					}
					this.fetchRecords(payload).then(
						(response) => {
							this.loading = false;
							this.ready = true;
							this.totalRecords = response.total_records;
							this.recordCount = response.record_count;
							this.pagination.rowsNumber = this.totalRecords;
							window.scrollTo(0, 0);
						},
						(response) => {
							this.loading = false;
							this.showPageRequestError(response);
						}
					);
				}
			},	
		},
		async mounted() {
		},
		async created() {
		}
	};
</script>
<style scoped>
</style>
<style scoped>

</style>
